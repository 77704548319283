.about-card{
    background: rgba( 255, 255, 255, 0.45 );
    box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.37 );
    backdrop-filter: blur( 4px );
    -webkit-backdrop-filter: blur( 4px );
    border-radius: 10px;
    border: 1px solid rgba( 255, 255, 255, 0.18 );
    padding: 10px 10px;
    margin: 0 35px;
    height: 280px;
    max-width: 25%
}

.about-main-title{
    margin-left: 24px;
    color: var(--font-color);
    font-family: 'Nunito-Bold';
    font-size: 2rem;
}

.about-line{
    width: 100%;
    height: 2px;
    background: var(--font-color);
}

.about-repo{
    margin: 10px 20px;
    display: flex;
    flex-flow: column;
}

.about-repo-header{
    margin: 10px 0;
    display: flex;
    flex-flow: row;
    align-content: center;
}

.about-repo-icon{
    width: 30px;
    height: 30px;
    color: var(--font-color);
}

.about-repo-link{
    margin-left: 5px;
    font-size: 1.2rem;
    font-family: 'Nunito-Bold';
    color: var(--link-color);
    text-decoration: none;
}

.about-repo-link:hover{
    text-decoration: underline;
}

.about-repo-link:focus{
    color: var(--link-color);
}

.about-repo-description{
    margin: 5px 5px;
    font-family: 'Nunito-Semi-Bold';
    font-size: 1.2rem;
}

@media only screen and (max-width: 480px) {
    .about-card{
        max-width: 90%;
        margin-bottom: 20px;
        height: fit-content;
    }

    .about-repo-description{
        margin: 5px 5px;
        font-family: 'Nunito-Semi-Bold';
        font-size: 1rem;
    }
}