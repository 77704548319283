.home-main{
    width: 100%;
    height: 100%;
}

#about {
    width: 70%;
}

.about-main-title{
    margin-left: 24px;
    color: var(--font-color);
    font-family: 'Nunito-Bold';
    font-size: 2rem;
}

.about-main-info{
    margin: 7px 0;
    color: var(--font-color);
    font-family: 'Nunito-Semi-Bold';
    font-size: 1.2rem;
    margin-left: 34px;
}

.about-link{
    font-family: 'Nunito-Bold';
    font-size: 1.2rem;
    color: var(--link-color);
    text-decoration: none;
}

.about-link:hover{
    text-decoration: underline;
}

.about-link:focus{
    font-family: 'Nunito';
    font-size: large;
    color: var(--link-color);
    text-decoration: none;
}

section {
    margin-left: 50px;
    display: none;
    opacity: 0;
}

input[type="radio"] {
    display: none;
}

@keyframes floatin {
    from {
        opacity: 0;
        margin-top: 120px;
    }

    to {
        opacity: 1;
        margin-top: 0;
    }
}

input[type='radio']#section1:checked ~ .selectors label[for="section1"] {
    background-color: var(--font-color);
}

input[type='radio']#section1:checked ~ #home {
    animation-name: floatin;
    animation-duration: 1s;
    animation-timing-function: ease-out;
    display: inline;
    opacity: 1;
}

input[type='radio']#section2:checked ~ .selectors label[for="section2"] {
    background-color: var(--font-color);
}

input[type='radio']#section2:checked ~ #about {
    animation-name: floatin;
    animation-duration: 1s;
    animation-timing-function: ease-out;
    display: inline;
    opacity: 1;
}

input[type='radio']#section3:checked ~ .selectors label[for="section3"] {
    background-color: var(--font-color);
}

input[type='radio']#section3:checked ~ #recent {
    animation-name: floatin;
    animation-duration: 1s;
    animation-timing-function: ease-out;
    display: inline;
    opacity: 1;
}

input[type='radio']#section4:checked ~ .selectors label[for="section4"] {
    background-color: var(--font-color);
}

input[type='radio']#section4:checked ~ #contact {
    animation-name: floatin;
    animation-duration: 1s;
    animation-timing-function: ease-out;
    display: inline;
    opacity: 1;
}

.home-content{
    display: flex;
    align-items: center;
    width: 100%;
    height: 90%;
}

.home-main-title{
    font-family: 'Nunito-Extra-Bold';
    color: var(--font-color);
    font-size: 4rem;
    margin: 0;
}

.home-main-title:hover{
    cursor: default;
}

.home-icons{
    margin: 0 15px;
    display: flex;
}

.home-icon-size-si{
    margin-top: 2.5px;
    margin-right: 2.5px;
    width: 1.6rem;
    height: 1.6rem;
}

.home-icon{
    margin-right: 10px;
}

.selectors {
    margin-left: auto;
    margin-right: 17px;
}

.radio-button{
    height: 12px;
    width: 12px;
    display: block;
	margin: 12px auto;
	border: solid 3px var(--font-color);
	border-radius: 50%;
	cursor: pointer;
}

.radio-button:hover {
    background-color: var(--font-color);
}

.recent-repo-wrapper {
    display: flex;
    flex-flow: row;
    max-width: 90%;
}

.contact-icon {
    margin-bottom: -2px;
}


.contact-link:focus {
    text-decoration: none;
    color: var(--font-color);
}

.contact-link:hover {
    text-decoration: underline;
}

.contact-link {
    padding: 0;
    margin: 0;
    text-decoration: none;
    color: var(--font-color);
}

@media only screen and (max-width: 480px) {
    .home-icons{
        margin: 0;
        padding: 0 30px 0 0;
        display: flex;
        flex-wrap: wrap;
    }

    .home-main-title{
        font-family: 'Nunito-Extra-Bold';
        color: var(--font-color);
        font-size: 3.5rem;
        margin: 0;
    }

    .home-content{
        margin: 20px 0;
        width: 90%;
        height: 100%;
    }

    section {
        margin-top: 90px;
        height: 100%;
        opacity: 1;
    }

    @keyframes floatin {
        from {
            opacity: 0;
            margin-top: 210px;
        }
    
        to {
            opacity: 1;
            margin-top: 90px;
        }
    }

    .selectors {
        position: fixed;
        margin-left: 30px;
        width: 50%;
        display: flex;
        flex-flow: row;
        z-index: 1000;
        left: 115px;
        top: 32px;
    }

    .about-main-info {
        margin-left: 21px
    }

    .recent-repo-wrapper {
        flex-flow: column;
    }
}