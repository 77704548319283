nav {
    position: fixed;
    top: 0;
    grid-area: header;
    width: 100%;
}

p{
    margin: 0;
}

#logo-container{
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: center;
    width: 17%;
}

#logo-text-container{
    display: flex;
    flex-flow: row;
    width: 100%;
    align-items: center;
    justify-content: center;
}

/*#logo-text-container > a{
    margin-left: 12px;
}*/

#logo-text{
    font-size: xx-large;
    color: var(--font-color);
    font-family: 'Nunito-Bold';
    opacity: 0.6;
    margin: 0;
    text-decoration: none;
}

#logo-text:focus{
    color: var(--font-color);
}

#logo-underline{
    border: 1px solid var(--font-color);
    border-radius: 24px;
    background-color: var(--font-color);
    opacity: 1;
    height: 5px;
    margin: 0 5px;
    width: 38%;
}

.selection-container{
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: center;
    width: 100%;
}

#selection-underline{
    display: flex;
    flex-flow: row;
    align-items: center;
    justify-content: center;
    width: 100%;
}

#divider{
    margin: 10px;
    font-family: 'Nunito';
    font-size: large;
    color: var(--font-color);
    opacity: 0.7;
}

.flex-selection::before{
    content: '';
    width: 100%;
    height: 100%;
    border-radius: 22px;
    position: absolute;
    opacity: 0.2;
    background: var(--font-color);
    box-shadow: inset 0 0 4000px rgba(255, 255, 255, .5);
}

.flex-selection
{
    display: flex;
    flex-flow: row;
    position: relative;
    align-items: center;
    justify-content: center;
    padding: 0 20px;
}

.feed-selector
{
    margin: 10px 0;
    z-index: 100;
    font-family: "Nunito";
    color: var(--font-color);
    text-decoration: none;
    transition: 0.5s;
}

.feed-selector:focus
{
    color: var(--font-color);
}

.feed-selector:hover
{
    color: var(--font-color);
    transition: 0.5s;
}

@media only screen and (max-width: 480px) {
    #logo-underline {
        width: 85px;
    }
}