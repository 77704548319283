.main-container{
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
  /*display: flex;
  flex-flow: column;*/
  font-family: 'Nunito', 'Courier New', Courier, monospace;
}

